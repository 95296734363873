import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import Container from 'react-bootstrap/Container'
import { useLocation } from 'react-router-dom'
import {
  AdminGetUserCommand,
  AdminListGroupsForUserCommand,
  AdminAddUserToGroupCommand,
  AdminRemoveUserFromGroupCommand,
  CognitoIdentityProviderClient,
} from '@aws-sdk/client-cognito-identity-provider'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { MDBInputGroup, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import Select from 'react-select'
import logo from '../../art/bandit.svg'

const _ = require('lodash')

export default function User() {
  const location = useLocation()
  const username = location.pathname.replace('/user/', '')

  const [user, setUser] = useState(null)
  const [group, setGroup] = useState('')
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({ action: '', content: '' })

  const groupsops = [
    { label: 'Admins', value: 'Admins' },
    { label: 'Users', value: 'Users' },
  ]

  useEffect(() => {
    onLoad()
  }, [])

  async function getClient() {
    try {
      const session = await Auth.currentSession()
      const idToken = session.getIdToken().getJwtToken()

      return new CognitoIdentityProviderClient({
        region: 'us-west-2',
        credentials: fromCognitoIdentityPool({
          identityPoolId: 'us-west-2:182427d1-1791-4c19-9ca9-bf2b306e655e',
          logins: {
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_XEhEW3MlE': idToken,
          },
          clientConfig: { region: 'us-west-2' },
        }),
      })
    } catch (error) {
      console.error('Error getting client:', error)
      throw error
    }
  }

  async function onLoad() {
    try {
      const client = await getClient()
      const getUserCommand = new AdminGetUserCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
        Username: username,
      })
      const userResponse = await client.send(getUserCommand)
      setUser(userResponse)

      const getGroupCommand = new AdminListGroupsForUserCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
        Username: username,
      })
      const groupResponse = await client.send(getGroupCommand)
      console.log(groupResponse)
      setGroup(groupResponse.Groups[0]?.GroupName || '')
    } catch (error) {
      console.error('Error loading user data:', error)
    }
  }

  async function addToGroup() {
    try {
      const client = await getClient()
      const currentGroup = group === 'Admins' ? 'Users' : 'Admins'

      const addCommand = new AdminAddUserToGroupCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
        Username: username,
        GroupName: group,
      })
      await client.send(addCommand)

      const removeCommand = new AdminRemoveUserFromGroupCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
        Username: username,
        GroupName: currentGroup,
      })
      await client.send(removeCommand)

      setToastContent({
        action: "'s permissions were updated.",
        content: username,
      })
      setToast(true)
    } catch (error) {
      console.error('Error updating user group:', error)
      setToastContent({
        action: 'Error updating permissions.',
        content: username,
      })
      setToast(true)
    }
  }

  function handleGroup(e) {
    if (e) {
      setGroup(e.value)
    }
  }

  return (
    <Container>
      <h2>User</h2>
      <hr />
      <MDBRow className="my-3 form-selects">
        <MDBCol md="6">
          <h3>{user?.Username}</h3>
          <h5>{user?.UserStatus}</h5>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup
            textBefore="Change Permission Group"
            className="mb-3 mt-2 form-selects"
          >
            <Select
              className="react-select"
              options={groupsops}
              value={groupsops.find((option) => option.value === group)}
              onChange={handleGroup}
            />
          </MDBInputGroup>
          <MDBBtn onClick={addToGroup}>SAVE</MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer position="middle-center">
        <Toast
          onClose={() => setToast(false)}
          show={toast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img src={logo} className="me-2" alt="" width="32px" />
            <strong className="me-auto">Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {toastContent.content}
            {toastContent.action}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  )
}
