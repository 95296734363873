import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import Container from 'react-bootstrap/Container'
import { MDBDatatable, MDBBtn, MDBIcon } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'
import {
  ListUsersCommand,
  CognitoIdentityProviderClient,
} from '@aws-sdk/client-cognito-identity-provider'

function Users() {
  const [users, setUsers] = useState({
    columns: [
      { label: 'Username', field: 'Username', sort: true },
      { label: 'Email', field: 'email', sort: false },
      { label: 'ID', field: 'id', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
    ],
    rows: [],
  })

  useEffect(() => {
    listUsers()
  }, [])

  async function listUsers() {
    try {
      const session = await Auth.currentSession()
      const idToken = session.getIdToken().getJwtToken()

      const client = new CognitoIdentityProviderClient({
        region: 'us-west-2',
        credentials: fromCognitoIdentityPool({
          identityPoolId: 'us-west-2:182427d1-1791-4c19-9ca9-bf2b306e655e',
          logins: {
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_XEhEW3MlE': idToken,
          },
          clientConfig: { region: 'us-west-2' },
        }),
      })

      const command = new ListUsersCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
      })

      const response = await client.send(command)
      let userlist = response.Users

      setUsers((prevState) => ({
        ...prevState,
        rows: userlist.map((row) => ({
          ...row,
          edit: (
            <React.Fragment>
              <MDBBtn size="sm" floating className="message-btn ms-2">
                <Link to={'/user/' + row.Username}>
                  <MDBIcon icon="edit" />
                </Link>
              </MDBBtn>
            </React.Fragment>
          ),
          email:
            row.Attributes.find((attr) => attr.Name === 'email')?.Value || '',
          id: row.Attributes.find((attr) => attr.Name === 'sub')?.Value || '',
        })),
      }))
    } catch (error) {
      console.error('Error listing users:', error)
    }
  }

  return (
    <Container>
      <h1>Users</h1>
      <hr />
      <MDBDatatable data={users} />
    </Container>
  )
}

export default Users
