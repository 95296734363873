import React, { useState, useEffect, useMemo } from 'react'
import { API, Auth } from 'aws-amplify'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import * as bandit from '../../graphql/bandit'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { useLocation } from 'react-router-dom'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import {
  MDBContainer,
  MDBDatatable,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBSwitch,
  MDBCol,
  MDBInput,
  MDBInputGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBRadio,
} from 'mdb-react-ui-kit'
import Select from 'react-select'
import { Badge, Toast, ToastContainer } from 'react-bootstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import logo from '../../art/bandit.svg'
import { thourops, relaynumberops } from './Options'
import useForm from '../Form/newCntrl'
import validate from '../Form/Validation'
import {
  SchedulerClient,
  CreateScheduleGroupCommand,
  CreateScheduleCommand,
  DeleteScheduleCommand,
  DeleteScheduleGroupCommand,
} from '@aws-sdk/client-scheduler'
import { nanoid } from 'nanoid'
var _ = require('lodash')

export default function Location() {
  const location = useLocation()
  let locationid = location.pathname
  locationid = locationid.replace('/location/', '')
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const myData = {
    locationid: locationid,
  }
  const [currentLocation, setCurrentLocation] = useState([])
  const [data, setAlertData] = useState([])
  const [controllerData, setControllerData] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Description', field: 'description', sort: false },
      { label: 'Date Created', field: 'created', sort: false },
      { label: 'Sensors', field: 'sensors', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })
  const [cameraData, setCameraData] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Active', field: 'active', sort: false },
      { label: 'Date Created', field: 'created', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })
  const [account, setAccount] = useState('')
  const [addctrlModal, setAddctrlModal] = useState(false)
  const toggleAddctrlModal = () => setAddctrlModal(!addctrlModal)
  const [newController, setNewController] = useState({
    locationid: locationid,
    dhcp: false,
  })
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({
    header: '',
    action: '',
    content: '',
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const toggleDelete = () => setDeleteModal(!deleteModal)
  const [deleteBuffer, setDeleteBuffer] = useState({
    name: '',
    id: '',
    _version: '',
    entity: '',
  })
  const [cameraModal, setCameraModal] = useState(false)
  const toggleCamera = () => setCameraModal(!cameraModal)
  const [newCamera, setNewCamera] = useState({
    id: 'c' + nanoid(),
    locationid: locationid,
    active: false,
    name: '',
    cameraControllerId: '',
  })
  const [controlselect, setControlselect] = useState([])
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    switchDebug,
    switchDhcp,
    pickDevice,
  } = useForm(addController, validate)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
    loadedRows: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [nextToken, setNextToken] = useState(undefined)
  const [credentials, setCredentials] = useState([])
  const [devices, setDevices] = useState()
  const limit = 100
  const columns = useMemo(() => [
    {
      header: 'Type',
      accessorKey: 'type',
      filterVariant: 'multi-select',
      filterSelectOptions: ['info', 'warning', 'error'],
      filterSelectPresets: ['warning', 'error'],
      maxSize: 20,
      Cell: ({ cell }) => {
        return (
          <React.Fragment>
            {' '}
            {cell.getValue() == 'error' ? (
              <Badge pill bg="danger">
                {cell.getValue()}
              </Badge>
            ) : (
              <Badge pill bg={cell.getValue()}>
                {cell.getValue()}
              </Badge>
            )}
          </React.Fragment>
        )
      },
    },
    { header: 'Message', accessorKey: 'message' },
    {
      header: 'Date Created',
      accessorKey: 'createdAt',
      Cell: ({ cell }) => {
        return (
          <React.Fragment>
            {' '}
            {moment(cell.getValue()).format('MMMM Do YYYY, h:mm:ss a')}
          </React.Fragment>
        )
      },
    },
    { header: 'Controller', accessorKey: 'controllerid' },
  ])

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilters: true,
    autoResetPageIndex: false,
    onPaginationChange: setPagination,
    state: {
      isLoading,
      pagination,
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiCircularProgressProps: {
      color: 'primary',
    },
    enableFacetedValues: true,
    initialState: { showColumnFilters: true },
  })

  async function getClient() {
    try {
      const session = await Auth.currentSession()
      const idToken = session.getIdToken().getJwtToken()

      return new SchedulerClient({
        region: 'us-west-2',
        credentials: fromCognitoIdentityPool({
          identityPoolId: 'us-west-2:182427d1-1791-4c19-9ca9-bf2b306e655e',
          logins: {
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_XEhEW3MlE': idToken,
          },
          clientConfig: { region: 'us-west-2' },
        }),
      })
    } catch (error) {
      console.error('Error getting client:', error)
      throw error
    }
  }

  async function onLoad() {
    setIsLoading(true)
    try {
      const creds = await Auth.currentUserCredentials()
      setCredentials(creds)
    } catch (error) {
      console.error(error)
    }
    try {
      const unreg = await API.graphql({ query: bandit.listUnregistereds })
      let devs = unreg.data.listUnregistereds.items
      devs = _.reject(devs, ['registered', true])
      var keys = { thing: 'label', arn: 'value' }
      var result = devs.map(function (o) {
        return _.mapKeys(o, function (v, k) {
          return k in keys ? keys[k] : k
        })
      })
      setDevices(result)
    } catch (error) {
      console.error(error)
    }
    try {
      let locdata = await API.graphql({
        query: bandit.getLocation,
        variables: { id: locationid },
      })
      locdata = locdata.data.getLocation
      setCurrentLocation(locdata)
      setNewController({ ...newController, accountid: locdata.accountid })
      const accdata = await API.graphql({
        query: queries.getAccount,
        variables: { id: locdata.accountid },
      })
      setAccount(accdata.data.getAccount.name)
      let controllerlist = locdata.controllers.items
      controllerlist = _.reject(controllerlist, ['_deleted', true])
      const cntrlselect = _.map(controllerlist, (item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
      setControlselect(cntrlselect)
      let cameralist = locdata.cameras.items
      cameralist = _.reject(cameralist, ['_deleted', true])
      setControllerData((prevState) => {
        return {
          ...prevState,
          rows: controllerlist.map((row) => {
            return {
              ...row,
              created: (
                <React.Fragment>
                  {moment(row.createdAt).format('LLL')}
                </React.Fragment>
              ),
              sensors: (
                <React.Fragment>
                  {row.sensors.items.map((sensor) => {
                    return (
                      <div key={sensor.id}>
                        <Link to={'/sensor/' + sensor.id}>
                          <p>{sensor.name}</p>
                        </Link>
                      </div>
                    )
                  })}
                </React.Fragment>
              ),
              edit: (
                <React.Fragment>
                  <MDBBtn size="sm" floating className="message-btn ms-2">
                    <Link to={`/controller/${row.id}`} state={myData}>
                      <MDBIcon icon="edit" />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn
                    size="sm"
                    floating
                    className="message-btn ms-2"
                    onClick={() => startDelete(row, 'controller')}
                  >
                    <MDBIcon icon="trash" />
                  </MDBBtn>
                </React.Fragment>
              ),
            }
          }),
        }
      })
      setCameraData((prevState) => {
        return {
          ...prevState,
          rows: cameralist.map((row) => {
            return {
              ...row,
              created: (
                <React.Fragment>
                  {moment(row.createdAt).format('LLL')}
                </React.Fragment>
              ),
              active: (
                <React.Fragment>
                  {row.active}
                  <MDBSwitch
                    name="active"
                    defaultChecked={row.active}
                    label=""
                    disabled
                  />
                </React.Fragment>
              ),
              edit: (
                <React.Fragment>
                  <MDBBtn size="sm" floating className="message-btn ms-2">
                    <Link to={'/camera/' + row.id}>
                      <MDBIcon icon="edit" />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn
                    size="sm"
                    floating
                    className="message-btn ms-2"
                    onClick={() => startDelete(row, 'camera')}
                  >
                    <MDBIcon icon="trash" />
                  </MDBBtn>
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  function setInput(key, value) {
    setCurrentLocation({ ...currentLocation, [key]: value })
  }

  function startDelete(row, entity) {
    var buffer = { ...row, entity }
    setDeleteBuffer(buffer)
    setDeleteModal(true)
  }

  function listDevices(e) {
    if (!devices == '') {
      return (
        <MDBInputGroup textBefore="Device" className="mb-3">
          <Select
            className="react-select reg-cntrl"
            options={devices}
            placeholder="REGISTER CONTROLLER"
            onChange={(e) => pickDevice('arn', e)}
          />
        </MDBInputGroup>
      )
    }
  }

  function validateCamera() {
    if (newCamera.name == '') {
      setToastContent({
        ...toastContent,
        content: 'ERROR : You have not selected a name for the camera',
      })
      setToast(true)
      return
    }
    if (newCamera.cameraControllerId == '') {
      setToastContent({
        ...toastContent,
        content: 'ERROR : You have not selected a controller for the camera',
      })
      setToast(true)
      return
    }
    addCamera()
  }

  async function addCamera(e) {
    e.preventDefault()
    try {
      await API.graphql({
        query: mutations.createCamera,
        variables: { input: newCamera },
      })
      const input = {
        Name: newCamera.id,
        Tags: [
          {
            Key: 'Camera',
            Value: newCamera.name,
          },
        ],
      }
      const ebs = {
        Name: newCamera.id, // required
        GroupName: newCamera.id,
        ScheduleExpression: 'rate(' + newCamera.alerthour + ' hours)',
        Description:
          'Camera Health Check : ' +
          newCamera.name +
          ' - ' +
          newCamera.description,
        ScheduleExpressionTimezone: 'America/Los_Angeles',
        State: 'ENABLED',
        Target: {
          Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:banditmqtt-${USER_BRANCH}`, // required
          RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`, // required
          RetryPolicy: {
            // RetryPolicy
            MaximumEventAgeInSeconds: Number(600),
            MaximumRetryAttempts: Number(3),
          },
          Input: `{ \"eventtype\": \"camera_api_request\",\"action\": \"TEST\",\"controllerid\": \"${newCamera.cameraControllerId}\",\"cameraid\": \"${newCamera.id}\",\"port\": \"0\",\"schedule\": \"${newCamera.id}\" }`,
        },
        FlexibleTimeWindow: {
          Mode: 'OFF', // required
        },
      }
      const client = await getClient()
      const command = new CreateScheduleGroupCommand(input)
      await client.send(command)
      const create = new CreateScheduleCommand(ebs)
      await client.send(create)
      setToastContent({
        header: '',
        action: 'created',
        content: newCamera.name,
      })
      setToast(true)
      setCameraModal(false)
      setNewCamera({
        locationid: locationid,
        name: '',
        ipaddress: '',
        portnumber: '',
        username: '',
        status: '',
        sensorpin: '',
        tamperpin: '',
        relaynumber: '',
      })
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  function setCamera(key, value) {
    setNewCamera({ ...newCamera, [key]: value })
  }

  function handleCamera(k, e) {
    if (e) {
      let v = e.value
      setNewCamera({ ...newCamera, [k]: v })
    }
  }

  const activateCamera = (e) => {
    setNewCamera({ ...newCamera, active: !newCamera.active })
  }

  async function updateLocatiton() {
    try {
      let cleaned = _.omit(currentLocation, [
        'alerts',
        'cameras',
        'controllers',
        'sensors',
        'createdAt',
        'updatedAt',
        '_lastChangedAt',
        '_deleted',
      ])
      await API.graphql({
        query: mutations.updateLocation,
        variables: { input: cleaned },
      })
      setToastContent({
        header: '',
        action: 'updated',
        content: currentLocation.name,
      })
      setToast(true)
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  async function addController() {
    try {
      await API.graphql({
        query: mutations.createController,
        variables: { input: values },
      })
      setToastContent({ header: '', action: 'created', content: values.name })
      setToast(true)
      setAddctrlModal(false)
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  async function deleteRecord() {
    const data = {
      id: deleteBuffer.id,
      _version: deleteBuffer._version,
    }
    try {
      if (deleteBuffer.entity === 'controller') {
        await API.graphql({
          query: mutations.deleteController,
          variables: { input: data },
        })
        deleteBuffer.sensors.items.map((sensor) => {
          deleteSensor(sensor)
        })
      }
      if (deleteBuffer.entity === 'sensor') {
        await API.graphql({
          query: mutations.deleteSensor,
          variables: { input: data },
        })
      }
      const client = await getClient()
      if (deleteBuffer.entity === 'camera') {
        await API.graphql({
          query: mutations.deleteCamera,
          variables: { input: data },
        })
        const ds = new DeleteScheduleCommand({
          Name: deleteBuffer.id,
          GroupName: deleteBuffer.id,
        })
        await client.send(ds)
        if (deleteBuffer.schedules.items.length > 0) {
          deleteBuffer.schedules.items.map((schedule) => {
            deleteSchedule(schedule)
          })
        }
        const dg = new DeleteScheduleGroupCommand({ Name: deleteBuffer.id })
        await client.send(dg)
      }
      setToastContent({
        header: '',
        action: 'deleted',
        content: deleteBuffer.name,
      })
      setToast(true)
      setDeleteModal(false)
    } catch (err) {
      console.log(err)
    }
  }

  async function deleteSchedule(schedule) {
    try {
      const client = await getClient()
      const ds0 = new DeleteScheduleCommand({
        Name: `${schedule.id}0`,
        GroupName: schedule.cameraid,
      })
      await client.send(ds0)
      const ds1 = new DeleteScheduleCommand({
        Name: `${schedule.id}1`,
        GroupName: schedule.cameraid,
      })
      await client.send(ds1)
    } catch (err) {
      console.log(err)
    }
  }

  async function deleteSensor(sensor) {
    try {
      await API.graphql({
        query: mutations.deleteSensor,
        variables: { input: { id: sensor.id, _version: sensor._version } },
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function pushConfig() {
    var payload = JSON.stringify(currentLocation)
    payload = payload.replace('"id":', '"eventtype":"configupdate","id":')
    console.log(`Payload sent to banditmqtt Lambda : `, payload)
    try {
      const lambdaClient = new LambdaClient({
        region: AWS_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })
      const command = new InvokeCommand({
        FunctionName: `banditmqtt-${USER_BRANCH}`,
        Payload: payload,
      })
      const response = await lambdaClient.send(command)
      console.log(`Response from Lambda : `, response) // Update state with Lambda response
    } catch (error) {
      console.error('Error invoking Lambda:', error)
    }
  }

  useEffect(() => {
    onLoad() // eslint-disable-line react-hooks/exhaustive-deps
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const fetch = async (load) => {
      setIsLoading(true)
      try {
        const variables = {
          nextToken,
          limit,
          sortDirection: 'DESC',
          locationid: locationid,
        }
        const result = await API.graphql({
          query: queries.alertsByLocationidAndCreatedAt,
          variables,
        })
        setNextToken(result.data.alertsByLocationidAndCreatedAt.nextToken)
        var alerts = result.data.alertsByLocationidAndCreatedAt.items
        //alerts = _.orderBy(alerts, ['createdAt'], ['desc']);
        var newLoad = pagination.loadedRows + alerts.length
        if (load === 1) {
          setAlertData(alerts)
        } else {
          setAlertData([...data, ...alerts])
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
        setPagination({ ...pagination, pageIndex: paged, loadedRows: newLoad })
      }
    }
    var paged = pagination.pageIndex
    if (pagination.loadedRows === 0) {
      fetch(1)
      return
    } else if (
      pagination.pageIndex * pagination.pageSize + pagination.pageSize !==
      pagination.loadedRows
    ) {
      return
    }
    fetch(0)
  }, [nextToken, pagination.pageIndex, pagination.pageSize])

  return (
    <React.Fragment>
      <MDBContainer className="content">
        <MDBRow>
          <MDBCol md="10">
            <h3>
              <a href={'/account/' + currentLocation.accountid}>{account}</a> /{' '}
              <a href={'/location/' + currentLocation.id}>
                {currentLocation.name}
              </a>
            </h3>
          </MDBCol>
          <MDBCol md="2" className="d-flex flex-row-reverse mb-2">
            <MDBBtn type="submit" color="danger" onClick={pushConfig}>
              Push Configuration
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <hr />
      </MDBContainer>
      <MDBContainer>
        <div className="d-flex flex-row justify-content-between w-100 mb-3 pb-3">
          <form className="d-flex flex-row justify-content-between w-100">
            <div className="me-3">
              <MDBInput
                label="Location Name"
                id="locname"
                value={currentLocation.name || ''}
                type="text"
                onChange={(event) => setInput('name', event.target.value)}
              />
            </div>
            <div className="flex-grow-1 me-3">
              <MDBTextArea
                label="Description"
                id="description"
                value={currentLocation.description || ''}
                rows={3}
                onChange={(event) =>
                  setInput('description', event.target.value)
                }
              />
            </div>
          </form>
          <div className="ms-5">
            <MDBBtn onClick={updateLocatiton}>Update Location</MDBBtn>
          </div>
        </div>
      </MDBContainer>
      <MDBContainer>
        <div className="d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom">
          <h4>Alerts</h4>
        </div>
        <MaterialReactTable table={table} />
      </MDBContainer>
      <MDBContainer>
        <div className="d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom">
          <h4>Cameras</h4>
          <MDBBtn onClick={toggleCamera}>
            <MDBIcon fas icon="plus-square" /> &nbsp;Add Camera
          </MDBBtn>
        </div>
        <MDBDatatable data={cameraData} entries={10} />
      </MDBContainer>
      <MDBContainer>
        <div className="d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom">
          <h4>Controllers</h4>
          <MDBBtn onClick={toggleAddctrlModal}>
            <MDBIcon fas icon="plus-square" /> &nbsp;Add Controller
          </MDBBtn>
        </div>
        <MDBDatatable data={controllerData} entries={10} />
      </MDBContainer>
      <MDBModal open={addctrlModal} setOpen={setAddctrlModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                Add a Controller to {currentLocation.name} location
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={handleSubmit} noValidate>
                <MDBRow className="my-3 form-selects">
                  <MDBCol md="6">
                    <h4>Controller : </h4>
                    <hr />
                    <div className="input-group mb-3">
                      <span className="input-group-text">Name</span>
                      <input
                        autoComplete="off"
                        className={`form-control ${
                          errors.name && 'text-danger'
                        }`}
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name || ''}
                        required
                      />
                    </div>
                    {errors.name && (
                      <p className="alert alert-danger">{errors.name}</p>
                    )}
                    <div className="input-group mb-3">
                      <span className="input-group-text">Description</span>
                      <input
                        autoComplete="off"
                        className={`form-control ${
                          errors.description && 'text-danger'
                        }`}
                        type="text"
                        name="description"
                        onChange={handleChange}
                        value={values.description || ''}
                        required
                      />
                    </div>
                    <MDBSwitch
                      className="mb-3"
                      name="debug"
                      checked={values.debug || ''}
                      onChange={switchDebug}
                      label="Enable Debug"
                    />
                    {errors.description && (
                      <p className="alert alert-danger">{errors.description}</p>
                    )}
                    {values.arn ? (
                      <div className="input-group mb-3">
                        <span className="input-group-text">Device</span>
                        <input
                          autoComplete="off"
                          className={`form-control ${
                            errors.arn && 'text-danger'
                          }`}
                          type="text"
                          name="arn"
                          onChange={handleChange}
                          value={values.arn || ''}
                          required
                        />
                      </div>
                    ) : (
                      listDevices()
                    )}
                    {errors.arn && (
                      <p className="alert alert-danger">{errors.arn}</p>
                    )}
                  </MDBCol>
                  <MDBCol md="6">
                    <h4>&nbsp;</h4>
                    <hr />
                    <MDBSwitch
                      className="mb-3"
                      name="dhcp"
                      checked={values.dhcp || ''}
                      onChange={switchDhcp}
                      label="Enable DHCP"
                    />
                    {values.dhcp != true && (
                      <React.Fragment>
                        <div className="input-group mb-3">
                          <span className="input-group-text">IP Address</span>
                          <input
                            autoComplete="off"
                            className={`form-control ${
                              errors.ipaddress && 'text-danger'
                            }`}
                            type="text"
                            name="ipaddress"
                            onChange={handleChange}
                            value={values.ipaddress || ''}
                          />
                        </div>
                        {errors.ipaddress && (
                          <p className="alert alert-danger">
                            {errors.ipaddress}
                          </p>
                        )}
                        <div className="input-group mb-3">
                          <span className="input-group-text">Subnet Mask</span>
                          <input
                            autoComplete="off"
                            className={`form-control ${
                              errors.netmask && 'text-danger'
                            }`}
                            type="text"
                            name="netmask"
                            onChange={handleChange}
                            value={values.netmask || ''}
                            required={!values.dhcp}
                          />
                        </div>
                        {errors.netmask && (
                          <p className="alert alert-danger">{errors.netmask}</p>
                        )}
                        <div className="input-group mb-3">
                          <span className="input-group-text">Gateway</span>
                          <input
                            autoComplete="off"
                            className={`form-control ${
                              errors.gateway && 'text-danger'
                            }`}
                            type="text"
                            name="gateway"
                            onChange={handleChange}
                            value={values.gateway || ''}
                            required={!values.dhcp}
                          />
                        </div>
                        {errors.gateway && (
                          <p className="alert alert-danger">{errors.gateway}</p>
                        )}
                        <div className="input-group mb-3">
                          <span className="input-group-text">DNS Server</span>
                          <input
                            autoComplete="off"
                            className={`form-control ${
                              errors.dns && 'text-danger'
                            }`}
                            type="text"
                            name="dns"
                            onChange={handleChange}
                            value={values.dns || ''}
                            required={!values.dhcp}
                          />
                        </div>
                        {errors.dns && (
                          <p className="alert alert-danger">{errors.dns}</p>
                        )}
                      </React.Fragment>
                    )}
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="submit" onClick={handleSubmit}>
                Add Controller
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={deleteModal} setOpen={setDeleteModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                Are you sure you want to delete the {deleteBuffer.entity}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleDelete}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <h4>{deleteBuffer.name}</h4>
              {deleteBuffer.id}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleDelete}>
                Cancel
              </MDBBtn>
              <MDBBtn onClick={deleteRecord}>
                Delete {deleteBuffer.entity}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={cameraModal} setOpen={setCameraModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                Add Camera to {currentLocation.name}{' '}
              </MDBModalTitle>
            </MDBModalHeader>
            <form data-mdb-validate onSubmit={addCamera}>
              <MDBModalBody>
                <MDBRow className="my-3 form-selects">
                  <MDBCol md="6">
                    <MDBInputGroup textBefore="Camera Name" className="mb-3">
                      <MDBInput
                        id="camname"
                        value={newCamera.name || ''}
                        type="text"
                        onChange={(event) =>
                          setCamera('name', event.target.value)
                        }
                        required
                      />
                    </MDBInputGroup>
                    <MDBInputGroup textBefore="Model" className="mb-3">
                      <MDBInput
                        id="model"
                        value={newCamera.model || ''}
                        type="text"
                        onChange={(event) =>
                          setCamera('model', event.target.value)
                        }
                      />
                    </MDBInputGroup>
                    <MDBInputGroup textBefore="MAC Address" className="mb-3">
                      <MDBInput
                        id="model"
                        value={newCamera.macaddress || ''}
                        type="text"
                        onChange={(event) =>
                          setCamera('macaddress', event.target.value)
                        }
                      />
                    </MDBInputGroup>
                    <MDBRadio
                      name="type"
                      id="analog"
                      checked={newCamera.type === 'analog'}
                      label="Analog"
                      inline
                      onChange={(event) => setCamera('type', 'analog')}
                    />
                    <MDBRadio
                      name="type"
                      id="digital"
                      checked={newCamera.type === 'digital'}
                      label="Digital"
                      inline
                      onChange={(event) => setCamera('type', 'digital')}
                      className="mb-3"
                    />
                    {newCamera.type === 'analog' && (
                      <MDBInputGroup
                        textBefore={'Relay Number'}
                        className="mb-3"
                      >
                        <Select
                          className="react-select"
                          options={relaynumberops}
                          placeholder={newCamera.relaynumber}
                          onChange={(e) => handleCamera('relaynumber', e)}
                        />
                      </MDBInputGroup>
                    )}
                    {newCamera.type === 'digital' && (
                      <React.Fragment>
                        <MDBInputGroup textBefore="IP Address" className="mb-3">
                          <MDBInput
                            id="ipaddress"
                            value={newCamera.ipaddress || ''}
                            type="text"
                            onChange={(event) =>
                              setCamera('ipaddress', event.target.value)
                            }
                          />
                        </MDBInputGroup>
                        <MDBInputGroup textBefore="Username" className="mb-3">
                          <MDBInput
                            id="username"
                            value={newCamera.username || ''}
                            type="text"
                            onChange={(event) =>
                              setCamera('username', event.target.value)
                            }
                          />
                        </MDBInputGroup>
                        <MDBInputGroup textBefore="Password" className="mb-3">
                          <MDBInput
                            id="password"
                            value={newCamera.password || ''}
                            type="password"
                            onChange={(event) =>
                              setCamera('password', event.target.value)
                            }
                          />
                        </MDBInputGroup>
                      </React.Fragment>
                    )}
                  </MDBCol>
                  <MDBCol md="6">
                    <h5>Camera Health Check</h5>
                    <MDBInputGroup
                      textBefore={'Test Interval'}
                      className="mb-3"
                    >
                      <Select
                        className="react-select"
                        options={thourops}
                        placeholder={newCamera.alerthour}
                        onChange={(e) => handleCamera('alerthour', e)}
                        required
                      />
                    </MDBInputGroup>
                    <h5>Connected Controller</h5>
                    <MDBInputGroup textBefore={'Controller'} className="mb-3">
                      <Select
                        className="react-select"
                        options={controlselect}
                        placeholder="Select a Controller"
                        onChange={(e) => handleCamera('cameraControllerId', e)}
                        required
                      />
                    </MDBInputGroup>{' '}
                    <MDBInputGroup className="my-3">
                      <MDBSwitch
                        className="mb-3"
                        name="active"
                        label="Active"
                        checked={newCamera.active}
                        onChange={activateCamera}
                      />
                    </MDBInputGroup>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn type="submit">Add Camera</MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer position="middle-center">
        <Toast
          onClose={() => setToast(false)}
          show={toast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img src={logo} className="me-2" alt="" width="32px" />
            <strong className="me-auto">Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {!toastContent.action
              ? toastContent.content
              : `${toastContent.content} has been ${toastContent.action} `}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </React.Fragment>
  )
}
