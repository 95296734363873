import React, { useState, useEffect } from 'react'
import { Auth, API } from 'aws-amplify'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import {
  SchedulerClient,
  GetScheduleCommand,
  UpdateScheduleCommand,
  CreateScheduleCommand,
} from '@aws-sdk/client-scheduler'
import * as bandit from '../../graphql/bandit'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { useLocation } from 'react-router-dom'
import {
  MDBContainer,
  MDBDatatable,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBInputGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBTimepicker,
  MDBSelect,
  MDBRadio,
  MDBSwitch,
} from 'mdb-react-ui-kit'
import Form from 'react-bootstrap/Form'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { Link } from 'react-router-dom'
import logo from '../../art/bandit.svg'
import moment from 'moment'
import {
  sensorpinops,
  relaytypeops,
  tamperpinops,
  portops,
  hourops,
} from './Options'
import Select from 'react-select'

var _ = require('lodash')

export default function Sensor() {
  const location = useLocation()
  let sensorid = location.pathname
  sensorid = sensorid.replace('/sensor/', '')
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const [formState, setFormState] = useState({
    name: 'name',
    description: undefined,
  })
  const [controller, setController] = useState()
  const [locid, setLocid] = useState()
  const [toast, setToast] = useState(false)
  const [toastcontent, setToastContent] = useState({
    name: '',
    action: '',
  })
  const [newAction, setNewAction] = useState({
    name: '',
    description: '',
    type: 'analog',
    sensorid: sensorid,
  })
  const [actionModal, setActionModal] = useState(false)
  const toggleAction = () => setActionModal(!actionModal)
  const [actionData, setActionData] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Camera', field: 'camera', sort: true },
      { label: 'Description', field: 'description', sort: true },
      { label: 'Date Created', field: 'created', sort: true },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })
  const [cameras, setCameras] = useState([])

  async function getClient() {
    try {
      const session = await Auth.currentSession()
      const idToken = session.getIdToken().getJwtToken()

      return new SchedulerClient({
        region: 'us-west-2',
        credentials: fromCognitoIdentityPool({
          identityPoolId: 'us-west-2:182427d1-1791-4c19-9ca9-bf2b306e655e',
          logins: {
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_XEhEW3MlE': idToken,
          },
          clientConfig: { region: 'us-west-2' },
        }),
      })
    } catch (error) {
      console.error('Error getting client:', error)
      throw error
    }
  }

  async function onLoad() {
    try {
      const sensordata = await API.graphql({
        query: bandit.getSensor,
        variables: { id: sensorid },
      })
      setFormState(sensordata.data.getSensor)
      setNewAction({
        ...newAction,
        controllerid: sensordata.data.getSensor.controllerid,
      })
      let actionlist = sensordata.data.getSensor.actions.items
      const cntdata = await API.graphql({
        query: queries.getController,
        variables: { id: sensordata.data.getSensor.controllerid },
      })
      setController(cntdata.data.getController.name)
      setLocid(cntdata.data.getController.locationid)
      let filter = {
        locationid: {
          eq: cntdata.data.getController.locationid,
        },
      }
      let cams = await API.graphql({
        query: queries.listCameras,
        variables: { filter: filter },
      })
      cams = cams.data.listCameras.items
      var keys = { name: 'label', id: 'value' }
      var result = cams.map(function (o) {
        return _.mapKeys(o, function (v, k) {
          return k in keys ? keys[k] : k
        })
      })
      setCameras(result)
      setActionData((prevState) => {
        return {
          ...prevState,
          rows: actionlist.map((row) => {
            return {
              ...row,
              camera: (
                <React.Fragment>
                  {row.camera && (
                    <Link to={'/camera/' + row.camera.id}>
                      {' '}
                      {row.camera.name}{' '}
                    </Link>
                  )}
                </React.Fragment>
              ),
              created: (
                <React.Fragment>
                  {moment(row.createdAt).format('MMMM Do YYYY, h:mm a')}
                </React.Fragment>
              ),
              edit: (
                <React.Fragment>
                  <MDBBtn size="sm" floating className="message-btn ms-2">
                    <Link to={'/action/' + row.id}>
                      <MDBIcon icon="edit" />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn
                    size="sm"
                    floating
                    className="message-btn ms-2"
                    onClick={() => trashAction(row)}
                  >
                    <MDBIcon icon="trash" />
                  </MDBBtn>
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function createSchedule() {
    const ebs = {
      Name: formState.id, // required
      ScheduleExpression: 'rate(15 minutes)',
      Description: 'ON : ' + formState.name + ' - ' + formState.description,
      ScheduleExpressionTimezone: 'America/Los_Angeles',
      State: 'ENABLED',
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:sensoralert-${USER_BRANCH}`,
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`,
        RetryPolicy: {
          MaximumEventAgeInSeconds: Number(600),
          MaximumRetryAttempts: Number(3),
        },
        Input: `{ \"interval\":\"${formState.alertinterval}\",\"increment\": \"${formState.alerttime}\",\"controllerid\": \"${formState.controllerid}\",\"sensorname\": \"${formState.name}\",\"sensorid\": \"${formState.id}\",\"schedule\": \"${formState.id}\",\"locationid\": \"${formState.locationid}\" }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF',
      },
    }
    try {
      const client = await getClient()
      const command = new CreateScheduleCommand(ebs)
      await client.send(command)
    } catch (err) {
      console.log(err)
    }
  }

  async function updateSensor() {
    const sencheck = {
      Name: formState.id, // required
    }
    try {
      const client = await getClient()
      const command = new GetScheduleCommand(sencheck)
      const response = await client.send(command)
      console.log(`CALL `, response)
    } catch (err) {
      createSchedule()
      console.log(`ERROR `, err)
    }
    const ebs = {
      Name: formState.id, // required
      ScheduleExpression: 'rate(15 minutes)',
      Description: 'ON : ' + formState.name + ' - ' + formState.description,
      ScheduleExpressionTimezone: 'America/Los_Angeles',
      State: 'ENABLED',
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:sensoralert-${USER_BRANCH}`,
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`,
        RetryPolicy: {
          MaximumEventAgeInSeconds: Number(600),
          MaximumRetryAttempts: Number(3),
        },
        Input: `{ \"interval\":\"${formState.alertinterval}\",\"increment\": \"${formState.alerttime}\",\"controllerid\": \"${formState.controllerid}\",\"sensorname\": \"${formState.name}\",\"sensorid\": \"${formState.id}\",\"schedule\": \"${formState.id}\",\"locationid\": \"${locid}\" }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF',
      },
    }
    try {
      let cleaned = _.omit(formState, [
        'actions',
        'blocks',
        'createdAt',
        'updatedAt',
        '_deleted',
        '_lastChangedAt',
      ])
      await API.graphql({
        query: mutations.updateSensor,
        variables: { input: cleaned },
      })
    } catch (err) {
      console.log(err)
    }
    try {
      const client = await getClient()
      const command = new UpdateScheduleCommand(ebs)
      const response = await client.send(command)
    } catch (err) {
      console.log(err)
    }
    setToastContent({ name: formState.name, action: 'has been updated.' })
    setToast(true)
  }

  async function trashAction(action) {
    const data = {
      id: action.id,
      _version: action._version,
    }
    try {
      await API.graphql({
        query: mutations.deleteAction,
        variables: { input: data },
      })
      setToastContent({ name: action.name, action: 'has been deleted.' })
      setToast(true)
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  function handleSelect(k, e) {
    if (e) {
      setFormState({ ...formState, [k]: e.value })
    }
  }

  async function addAction() {
    try {
      await API.graphql({
        query: mutations.createAction,
        variables: { input: newAction },
      })
      setToastContent({
        header: '',
        action: 'Action Created',
        content: newAction.name,
      })
      setToast(true)
      setActionModal(false)
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  function setInput(k, v) {
    setFormState({ ...formState, [k]: v })
  }

  function setAction(key, value) {
    setNewAction({ ...newAction, [key]: value })
  }

  function handleAction(k, e) {
    if (e) {
      setNewAction({ ...newAction, [k]: e.value })
    }
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <MDBContainer className="content">
        <MDBRow>
          <h2>
            <a href={'/controller/' + formState.controllerid}>{controller}</a> /{' '}
            {formState.name || ''}
          </h2>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer className="content">
        <MDBRow>
          <form>
            <MDBRow className="my-3 form-selects">
              <MDBCol md="6">
                <h4>Sensor : </h4>
                <hr />
                <MDBInput
                  className="mb-3"
                  label="Sensor Name"
                  id="name"
                  value={formState.name || ''}
                  type="text"
                  onChange={(event) => setInput('name', event.target.value)}
                />
                <MDBInput
                  className="mb-3"
                  label="Description"
                  id="name"
                  value={formState.description || ''}
                  type="text"
                  onChange={(event) =>
                    setInput('description', event.target.value)
                  }
                />
                <label htmlFor="alerttime" className="form-label">
                  Last Alert Interval
                </label>
                <MDBInputGroup className="border p-2 rounded-2">
                  <MDBRadio
                    name="alerttime"
                    id="alerttime"
                    checked={formState.alerttime === 'hours'}
                    label="Hours"
                    inline
                    onChange={(event) => setInput('alerttime', 'hours')}
                  />
                  <MDBRadio
                    name="alerttime"
                    id="alerttime"
                    checked={formState.alerttime === 'days'}
                    label="Days"
                    inline
                    onChange={(event) => setInput('alerttime', 'days')}
                    className="mb-3"
                  />

                  {formState.alerttime === 'hours' && (
                    <MDBInputGroup textBefore="Hours" className="mb-3">
                      <Select
                        required
                        className="react-select"
                        options={hourops}
                        placeholder={formState.alertinterval}
                        onChange={(e) => handleSelect('alertinterval', e)}
                      />
                    </MDBInputGroup>
                  )}
                  {formState.alerttime === 'days' && (
                    <React.Fragment>
                      <MDBInputGroup textBefore="Days" className="mb-3">
                        <MDBInput
                          id="days"
                          required
                          type="number"
                          label={formState.alertinterval}
                          placeholder={formState.alertinterval}
                          onChange={(e) =>
                            setInput('alertinterval', e.target.value)
                          }
                          alwaysShowPlaceholder
                        />
                      </MDBInputGroup>
                    </React.Fragment>
                  )}
                </MDBInputGroup>
              </MDBCol>
              <MDBCol md="6">
                <h4>Input : </h4>
                <hr />
                <MDBInputGroup textBefore="Sensor Pin" className="mb-3">
                  <Select
                    className="react-select"
                    options={sensorpinops}
                    placeholder={formState.sensorpin}
                    onChange={(e) => handleSelect('sensorpin', e)}
                  />
                </MDBInputGroup>
                <MDBInputGroup textBefore="Sensor Relay Type" className="mb-3">
                  <Select
                    className="react-select"
                    options={relaytypeops}
                    placeholder={formState.relaytype}
                    onChange={(e) => handleSelect('relaytype', e)}
                  />
                </MDBInputGroup>
                <MDBInputGroup textBefore="Pi Tamper Pin" className="mb-3">
                  <Select
                    className="react-select"
                    options={tamperpinops}
                    placeholder={formState.tamperpin}
                    onChange={(e) => handleSelect('tamperpin', e)}
                  />
                </MDBInputGroup>
              </MDBCol>
            </MDBRow>
          </form>
          <MDBRow className="my-3">
            <MDBCol md="10"></MDBCol>
            <MDBCol md="2" className="d-flex flex-row-reverse">
              <MDBBtn onClick={updateSensor}>Update Sensor</MDBBtn>
            </MDBCol>
          </MDBRow>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <div className="d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom">
          <h4>Actions</h4>
          <MDBBtn onClick={toggleAction}>
            <MDBIcon fas icon="plus-square" /> &nbsp;Add Action
          </MDBBtn>
        </div>
        <MDBDatatable data={actionData} entries={10} />
      </MDBContainer>
      <MDBModal open={actionModal} setOpen={setActionModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add an Action</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="my-3 form-selects">
                <MDBCol md="6">
                  <MDBInput
                    className="mb-3"
                    id="name"
                    label="Name"
                    value={newAction.name || ''}
                    type="text"
                    onChange={(event) => setAction('name', event.target.value)}
                  />
                  <MDBTextArea
                    className="mb-3"
                    id="description"
                    label="Description"
                    value={newAction.description || ''}
                    type="text"
                    onChange={(event) =>
                      setAction('description', event.target.value)
                    }
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBRadio
                    name="type"
                    id="analog"
                    checked={newAction.type === 'analog'}
                    label="Analog"
                    inline
                    onChange={(event) => setAction('type', 'analog')}
                  />
                  <MDBRadio
                    name="type"
                    id="digital"
                    checked={newAction.type === 'digital'}
                    label="Digital"
                    inline
                    onChange={(event) => setAction('type', 'digital')}
                    className="mb-3"
                  />
                  {newAction.type === 'analog' ? (
                    <MDBInputGroup textBefore="PI Relay Num" className="mb-3">
                      <MDBInput
                        id="relaynumber"
                        value={newAction.relaynumber || ''}
                        type="text"
                        onChange={(event) =>
                          setAction('relaynumber', event.target.value)
                        }
                      />
                    </MDBInputGroup>
                  ) : (
                    <React.Fragment>
                      <MDBInputGroup textBefore="Camera" className="mb-3">
                        <Select
                          className="react-select"
                          options={cameras}
                          onChange={(e) => handleAction('actionCameraId', e)}
                        />
                      </MDBInputGroup>
                      <MDBInputGroup textBefore="Port Number" className="mb-3">
                        <Select
                          className="react-select"
                          options={portops}
                          onChange={(e) => handleAction('portnumber', e)}
                        />
                      </MDBInputGroup>
                    </React.Fragment>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={addAction}>Add Action</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer position="middle-center">
        <Toast
          onClose={() => setToast(false)}
          show={toast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img src={logo} className="me-2" alt="Bandit Logo" width="32px" />
            <strong className="me-auto">Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {toastcontent.name} {toastcontent.action}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </React.Fragment>
  )
}
